import "core-js/modules/es.error.cause.js";
import { alertMessage } from '@/assets/js/tool';
import { shareUpdate } from '@/service/index.js';
import { ElLoading } from 'element-plus';
export default {
  emits: ['success'],
  data() {
    let validateCom = (rule, value, callback) => {
      let field = rule.field;
      let match = /[\\/:*?|"<>~!@#$%^.&]/;
      let text = '\\ /: *? "< > | ~ ! @ # $ % ^ . &';
      //name version 运行输入 .
      if (field === 'name' || field === 'version') {
        match = /[\\/:*?|"<>~!@#$%^&]/;
        text = '\\ /: *? "< > | ~ ! @ # $ % ^  &';
      }
      //brand  author 运行输入 .&
      if (field === 'brand' || field === 'author') {
        match = /[\\/:*?|"<>~!@#$%^]/;
        text = '\\ /: *? "< > | ~ ! @ # $ % ^';
      }
      if (value.trim() === '') {
        //首字母转大写
        let key = field.substring(0, 1).toUpperCase() + field.substring(1);
        key = 'PleaseEnter' + key;
        callback(new Error(this.$t(`message.${key}`)));
      } else if (value.match(match) != null) {
        callback(new Error(this.$t('message.IllegalCharacter') + text));
      } else {
        callback();
      }
    };
    let validateKeywords = (rule, value, callback) => {
      value = value.replace(/，/g, ',');
      let arr = value.split(',');
      let bool = true;
      arr.forEach(item => {
        if (item.length > 20) bool = false;
      });
      if (value === '') {
        callback(new Error(this.$t('message.PleaseEnterKeywords')));
      } else if (value.match(/[\\/:*?|"<>~!@#$%^.&]/) != null) {
        let text = '\\ /: *? "< > | ~ ! @ # $ % ^ . &';
        callback(new Error(this.$t('message.IllegalCharacter') + text));
      } else if (arr.length > 5) {
        callback(new Error(this.$t('message.EnterUptoFiveKeywords')));
      } else if (!bool) {
        callback(new Error(this.$t('message.KeywordsLength20')));
      } else {
        callback();
      }
    };
    return {
      title: '',
      dialogVisible: false,
      showForm: true,
      ruleForm: {
        name: '',
        version: '',
        keywords: '',
        brand: '',
        deviceName: '',
        author: '',
        description: '',
        open: true
      },
      rules: {
        name: [{
          required: true,
          validator: validateCom,
          trigger: 'change'
        }],
        version: [{
          required: true,
          validator: validateCom,
          trigger: 'change'
        }],
        keywords: [{
          required: true,
          validator: validateKeywords,
          trigger: 'change'
        }],
        author: [{
          required: true,
          validator: validateCom,
          trigger: 'change'
        }],
        brand: [{
          required: true,
          validator: validateCom,
          trigger: 'change'
        }],
        deviceName: [{
          required: true,
          validator: validateCom,
          trigger: 'change'
        }]
      },
      type: -1
    };
  },
  computed: {
    //我的资源列表
    mineList() {
      return this.$store.state.mineList;
    },
    lang() {
      return this.$store.getters.getLang;
    }
  },
  methods: {
    init(row) {
      let type = row.resourcesType;
      this.type = type;
      this.initTitle(type);
      this.initRuleForm(row);
    },
    //初始化标题
    initTitle(type) {
      let title = '';
      if (type === 0) title = this.$t('message.DriverUpdate');
      if (type === 1) title = this.$t('message.PackageUpdate');
      if (type === 2) title = this.$t('message.UITemplateUpdate');
      this.title = title;
    },
    //初始化参数
    initRuleForm(row) {
      this.ruleForm.id = row.id;
      this.ruleForm.name = row.name || '';
      this.ruleForm.version = row.version || '';
      this.ruleForm.keywords = row.keywords || '';
      this.ruleForm.brand = row.brand || '';
      this.ruleForm.deviceName = row.deviceName || '';
      this.ruleForm.author = row.author || '';
      this.ruleForm.description = row.description || '';
      this.ruleForm.open = row.open || false;
    },
    //打开
    async open(row) {
      this.init(row);
      this.showForm = false;
      this.dialogVisible = true;
      await this.$nextTick();
      this.showForm = true;
    },
    //保存
    confirm() {
      this.$refs.ruleFormRef.validate(async valid => {
        if (valid) {
          let ruleForm = {
            id: this.ruleForm.id,
            name: this.ruleForm.name.trim() || '',
            version: this.ruleForm.version.trim() || '',
            keywords: this.ruleForm.keywords.trim() || '',
            brand: this.ruleForm.brand.trim() || '',
            deviceName: this.ruleForm.deviceName.trim() || '',
            author: this.ruleForm.author.trim() || '',
            description: this.ruleForm.description || '',
            open: this.ruleForm.open
          };
          if (this.type === 2) {
            delete ruleForm.brand;
            delete ruleForm.deviceName;
          }
          let id = ruleForm.id;
          let saveLoading = ElLoading.service({
            lock: true,
            background: 'rgba(0, 0, 0, 0)'
          });
          let res = await shareUpdate(this.type, ruleForm);
          //let res = { status: 0 };
          saveLoading.close();
          if (res.status === 0) {
            alertMessage('success', this.$t('message.OperationSucceeded'));
            //修改状态为审核中
            let dataInAll = this.mineList.all.find(item => item.id === id);
            let dataInDriver = this.mineList.driver.find(item => item.id === id);
            let dataInPackage = this.mineList.package.find(item => item.id === id);
            let dataInUi = this.mineList.template.find(item => item.id === id);
            //修改状态
            let checking = 'Checking';
            ruleForm.shareStatus = checking;
            this.updateRow(dataInAll, ruleForm);
            this.updateRow(dataInDriver, ruleForm);
            this.updateRow(dataInPackage, ruleForm);
            this.updateRow(dataInUi, ruleForm);
            this.$emit('success', ruleForm);
            this.close();
          }
        } else {
          return false;
        }
      });
    },
    //取消
    close() {
      this.dialogVisible = false;
    },
    //修改该行数据
    updateRow(data, ruleForm) {
      if (data) {
        data.shareStatus = ruleForm.shareStatus;
        data.name = ruleForm.name || '';
        data.version = ruleForm.version || '';
        data.keywords = ruleForm.keywords || '';
        data.brand = ruleForm.brand || '';
        data.deviceName = ruleForm.deviceName || '';
        data.author = ruleForm.author || '';
        data.description = ruleForm.description || '';
        data.open = ruleForm.open || false;
      }
    }
  }
};