import {
  ref,
} from 'vue';
import {
  useI18n
} from 'vue-i18n';
import {
  useStore
} from 'vuex';

export default function useSort(initShareList, initMineList) {
  //store
  const store = useStore();
  //data
  const {
    t
  } = useI18n();
  //排序-分类
  const sort = ref({
    //驱动 驱动包 UI模板排序
    driver: {
      selected: 'Name',
      options: [{
          label: t('message.name2'),
          value: 'Name',
        },
        {
          label: t('message.PublishDate'),
          value: 'DateAsc',
          icon: 'icon-top',
        },
        {
          label: t('message.PublishDate'),
          value: 'DateDesc',
          icon: 'icon-bottom',
        },
      ],
    },
    type: {
      selected: 'All',
      options: [], //展示的数据
      templates: [], //UI模板数据
      drivers: [], //驱动 驱动包数据
    },
    //我的资源排序
    mine: {
      selected: 'Default',
      options: [{
          label: t('message.Default'),
          value: 'Default',
        },
        {
          label: t('message.name2'),
          value: 'Name',
        },
      ],
    },
    info: {
      selected: 'all',
      options: [{
          label: t('message.AllType'),
          value: 'all',
        },
        {
          label: t('message.UITemplate'),
          value: 'template',
        },
        {
          label: t('message.DriverCode'),
          value: 'driver',
        },
        {
          label: t('message.DrivePackage'),
          value: 'package',
        },
      ],
    },
    status: {
      selected: 'All',
      options: [{
          label: t('message.AllStatus'),
          value: 'All',
        },
        {
          label: t('message.Reject'),
          value: 'Reject',
        },
        {
          label: t('message.ToSubmit'),
          value: 'ToSubmit',
        },
        {
          label: t('message.Checking'),
          value: 'Checking',
        },
        {
          label: t('message.Deployed'),
          value: 'Deployed',
        },
      ],
    },
  });

  //function
  /**初始化排序*/
  function initSort(params = {}) {
    let sortVal = sort.value;
    sortVal.driver.selected = params.S_deployTime ? params.S_deployTime === 'asc' ? 'DateAsc' : 'DateDesc' : 'Name';
    sortVal.type.selected = params['Q_EQ_uiType.id'] ? params['Q_EQ_uiType.id'] : (params['Q_EQ_driverType.id'] ? params['Q_EQ_driverType.id'] : 'All');
    sortVal.mine.selected = params.S_name ? 'Name' : 'Default';
    sortVal.status.selected = params.Q_EQ_shareStatus ? params.Q_EQ_shareStatus : 'All';
    sortVal.info.selected = params.Q_Type ? params.Q_Type : 'all';
  };

  /**选择了排序*/
  function handleSortChange(val, key) {
    let resourceNav = store.state.resourceNav;
    //清空滚动高度
    let scroll = store.state.shareScroll[resourceNav];
    if (scroll) scroll.top = 0;
    //我的资源排序
    if (resourceNav === 'mine') {
      let mineParams = JSON.parse(JSON.stringify(store.state.mineParams));
      if (key === 'mine') {
        if (val === 'Name') {
          mineParams['S_name'] = 'asc';
        } else {
          delete mineParams['S_name'];
        }
      } else if (key === 'status') {
        if (val !== 'All') {
          mineParams['Q_EQ_shareStatus'] = val;
        } else {
          delete mineParams['Q_EQ_shareStatus'];
        };
      } else if (key === 'info') {
        if (val !== 'all') {
          mineParams['Q_Type'] = val;
        } else {
          delete mineParams['Q_Type'];
        };
      };
      store.commit('setMineParams', mineParams);
      initMineList(mineParams);
    } else {
      //驱动 驱动包 UI模板排序
      let shareParams = JSON.parse(JSON.stringify(store.state.shareParams[resourceNav]));
      if (key === 'driver') {
        if (val === 'Name') {
          shareParams['S_name'] = 'asc';
          shareParams['S_version'] = 'asc';
          delete shareParams['S_deployTime'];
        } else {
          let newVal = val === 'DateAsc' ? 'asc' : 'desc';
          shareParams['S_deployTime'] = newVal;
          delete shareParams['S_name'];
          delete shareParams['S_version'];
        };
      } else if (key === 'typeUi') {
        delete shareParams['Q_EQ_driverType.id'];
        shareParams['Q_EQ_uiType.id'] = val;
        if (val === 'All') delete shareParams['Q_EQ_uiType.id'];
      } else if (key === 'typeDriver') {
        delete shareParams['Q_EQ_uiType.id'];
        shareParams['Q_EQ_driverType.id'] = val;
        if (val === 'All') delete shareParams['Q_EQ_driverType.id'];
      }
      store.commit('setShareParams', {
        key: resourceNav,
        val: shareParams
      });
      initShareList(shareParams);
    };
  };

  return {
    //data
    sort,
    //function
    initSort,
    handleSortChange,
  }
};