import "core-js/modules/es.array.push.js";
import { ref, computed, watch, nextTick, onUnmounted } from 'vue';
import { useI18n } from 'vue-i18n';
import { getUiTypeList, getDriverTypeList, getVersionList } from '@/service/index.js';
import { Search } from '@element-plus/icons-vue';
import { ElLoading } from 'element-plus';
import { useStore } from 'vuex';
import useSort from './use-sort';
import useSearch from './use-search';
import useList from './use-list';
import useNav from './use-nav';
import AdaptiveSelected from '../../components/Basic/AdaptiveSelect.vue';
import ListScroll from './comp/ListScroll.vue';
import ImageLook from '../../components/Basic/ImageLook.vue';
export default {
  components: {
    AdaptiveSelected,
    Search,
    ListScroll,
    ImageLook
  },
  setup() {
    //store
    const store = useStore();
    //computed
    const lang = computed(() => store.getters.getLang);
    const count = computed(() => store.state.resourceCount);
    const loading = computed(() => store.state.loading);

    //data
    const resourceLoading = ref(null);
    const {
      t
    } = useI18n();
    const typeOptions = ref({
      templateZH: [],
      templateEN: [],
      driverZH: [],
      driverEN: []
    });
    const {
      initShareList,
      initMineList
    } = useList();
    const {
      sort,
      initSort,
      handleSortChange
    } = useSort(initShareList, initMineList);
    const {
      search,
      handleSearch
    } = useSearch(initShareList, initMineList);
    const {
      navList,
      navCheck,
      handleNavClick
    } = useNav(sort, search, initSort, initShareList, initMineList);

    //watch
    //左侧导航栏
    watch(() => navCheck.value.value, newVal => {
      if (newVal) store.state.resourceNav = newVal;
    });
    //加载中
    watch(loading, newLoading => {
      if (newLoading) {
        showLoading();
      } else {
        closeLoading();
      }
    });
    //多语言
    watch(lang, async newLang => {
      initDataLang(newLang);
    });

    //created
    initParams();
    initAllList();

    //页面卸载
    onUnmounted(() => {
      store.state.shareParams = {
        template: null,
        driver: null,
        package: null
      };
      store.state.shareList = {
        template: null,
        driver: null,
        package: null
      };
      store.state.mineParams = {};
      store.state.mineList = {
        template: [],
        driver: [],
        package: [],
        all: []
      };
    });

    //function
    //切换sort下来选项多语言
    async function initDataLang(newLang) {
      const sortVal = sort.value;
      let deiverOption = sortVal.driver.options;
      deiverOption[0].label = t('message.name2');
      deiverOption[1].label = t('message.PublishDate');
      deiverOption[2].label = t('message.PublishDate');
      let mineOption = sortVal.mine.options;
      mineOption[0].label = t('message.Default');
      mineOption[1].label = t('message.name2');
      let infoOption = sortVal.info.options;
      infoOption[0].label = t('message.AllType');
      infoOption[1].label = t('message.UITemplate');
      infoOption[2].label = t('message.DriverCode');
      infoOption[3].label = t('message.DrivePackage');
      let statusOption = sortVal.status.options;
      statusOption[0].label = t('message.AllStatus');
      statusOption[1].label = t('message.Reject');
      statusOption[2].label = t('message.ToSubmit');
      statusOption[3].label = t('message.Checking');
      statusOption[4].label = t('message.Deployed');
      //重新获取分类数据
      let templateKey = newLang === 'en' ? 'templateEN' : 'templateZH';
      let driverKey = newLang === 'en' ? 'driverEN' : 'driverZH';
      let resKey = navCheck.value.value === 'template' ? templateKey : driverKey;
      let type = sortVal.type;
      type.options = [];
      await nextTick();
      type.options = typeOptions.value[resKey];
      type.drivers = typeOptions.value[driverKey];
      type.templates = typeOptions.value[templateKey];
    }

    /**打开loading*/
    function showLoading() {
      resourceLoading.value = ElLoading.service({
        lock: true,
        background: 'rgba(0, 0, 0, 0)',
        customClass: 'resource-loading'
      });
    }

    /**关闭loading*/
    function closeLoading() {
      resourceLoading.value.close();
    }

    /**初始化参数*/
    function initParams() {
      //资源市场通用参数
      const shareParamsCommon = {
        page: 1,
        rows: 10000,
        S_name: 'asc',
        S_version: 'asc'
      };
      store.state.shareParams = {
        template: {
          type: 2,
          ...shareParamsCommon
        },
        driver: {
          type: 0,
          ...shareParamsCommon
        },
        package: {
          type: 1,
          ...shareParamsCommon
        }
      };
      store.state.shareScroll = {
        template: {
          top: 0,
          page: 1
        },
        driver: {
          top: 0,
          page: 1
        },
        package: {
          top: 0,
          page: 1
        }
      };
    }

    /**获取所有数据*/
    async function initAllList() {
      store.state.loading = true;
      //所有版本信息
      let versionList = await getVersionList();
      if (!versionList.length) {
        store.state.loading = false;
        return;
      }
      //转换为对象
      let data = {};
      versionList.forEach(item => {
        let key = item.versionCode + '';
        data[key] = item.version;
        store.state.allVersion = data;
      });
      //ui模板分类列表
      let type = sort.value.type;
      let uiTypeList = await getUiTypeList();
      if (!uiTypeList.length) {
        store.state.loading = false;
        return;
      }
      let optionType = getOptions(uiTypeList, 'template');
      type.options = optionType;
      //驱动分类列表
      let driverTypeList = await getDriverTypeList();
      if (!driverTypeList.length) {
        store.state.loading = false;
        return;
      }
      type.templates = optionType;
      type.drivers = getOptions(driverTypeList, 'driver');
      //获取数据列表-初始为ui模板
      let params = store.state.shareParams.template;
      initShareList(params);
    }

    //获取sort下拉选项
    function getOptions(arr, key) {
      let chineseOptions = [];
      let englishOptions = [];
      arr.forEach(item => {
        chineseOptions.push({
          label: item.zhName,
          value: item.id
        });
        englishOptions.push({
          label: item.enName,
          value: item.id
        });
      });
      let ZHKey = key + 'ZH';
      let ENKey = key + 'EN';
      typeOptions.value[ZHKey] = chineseOptions;
      typeOptions.value[ENKey] = englishOptions;
      let resKey = lang.value === 'en' ? ENKey : ZHKey;
      return typeOptions.value[resKey];
    }
    return {
      //use-nav
      navCheck,
      navList,
      handleNavClick,
      //use-sort
      sort,
      handleSortChange,
      //use-search
      search,
      handleSearch,
      //computed
      lang,
      count
    };
  }
};