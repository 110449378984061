import { resolveComponent as _resolveComponent, withModifiers as _withModifiers, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue";
const _hoisted_1 = {
  class: "picture-look-header"
};
const _hoisted_2 = ["src"];
const _hoisted_3 = {
  class: "operation"
};
const _hoisted_4 = ["src"];
const _hoisted_5 = ["src"];
const _hoisted_6 = ["src"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_close = _resolveComponent("close");
  return $data.show ? (_openBlock(), _createElementBlock("div", {
    key: 0,
    class: "picture-look-wrap",
    onClick: _cache[11] || (_cache[11] = $event => $data.notClose = true)
  }, [_createElementVNode("div", _hoisted_1, [_createVNode(_component_close, {
    onClick: _withModifiers($options.close, ["stop"])
  }, null, 8, ["onClick"])]), _createElementVNode("div", {
    class: "picture-look-content",
    style: _normalizeStyle({
      width: $data.contentWidth + 'px',
      height: $data.contentHeight + 'px'
    }),
    onMouseenter: _cache[9] || (_cache[9] = (...args) => $options.mouseenter && $options.mouseenter(...args)),
    onMouseleave: _cache[10] || (_cache[10] = (...args) => $options.mouseleave && $options.mouseleave(...args))
  }, [_createElementVNode("img", {
    src: $options.image,
    alt: "",
    class: "img-content",
    style: _normalizeStyle({
      transform: `translate(${$data.posX}px,${$data.posY}px) scale(${$data.zoom / 100})`
    }),
    draggable: "false",
    ref: "imgRef"
  }, null, 12, _hoisted_2), _createElementVNode("div", _hoisted_3, [_createElementVNode("img", {
    src: $data.img.narrow,
    onClick: _cache[0] || (_cache[0] = (...args) => $options.subtraction && $options.subtraction(...args)),
    onMousedown: _cache[1] || (_cache[1] = $event => $data.img.narrow = require('../../assets/img/narrow-2.png')),
    onMouseup: _cache[2] || (_cache[2] = $event => $data.img.narrow = require('../../assets/img/narrow.png'))
  }, null, 40, _hoisted_4), _createElementVNode("span", null, _toDisplayString($data.zoom) + "%", 1), _createElementVNode("img", {
    src: $data.img.enlarge,
    onClick: _cache[3] || (_cache[3] = (...args) => $options.add && $options.add(...args)),
    onMousedown: _cache[4] || (_cache[4] = $event => $data.img.enlarge = require('../../assets/img/enlarge-2.png')),
    onMouseup: _cache[5] || (_cache[5] = $event => $data.img.enlarge = require('../../assets/img/enlarge.png'))
  }, null, 40, _hoisted_5), _createElementVNode("img", {
    src: $data.img['1to1'],
    onClick: _cache[6] || (_cache[6] = (...args) => $options.oneToOne && $options.oneToOne(...args)),
    onMousedown: _cache[7] || (_cache[7] = $event => $data.img['1to1'] = require('../../assets/img/1to1-2.png')),
    onMouseup: _cache[8] || (_cache[8] = $event => $data.img['1to1'] = require('../../assets/img/1to1.png'))
  }, null, 40, _hoisted_6)])], 36)])) : _createCommentVNode("", true);
}