import { downloadFile } from '@/assets/js/tool.js';
import { getResourceDownloadUrl } from '@/service/index.js';
import ListContent from './ListContent.vue';
import Withdraw from './Withdraw.vue';
import Delete from './Delete.vue';
import Save from './Save.vue';
export default {
  components: {
    ListContent,
    Withdraw,
    Delete,
    Save
  },
  props: {
    list: {
      type: Array,
      default() {
        return [];
      }
    }
  },
  methods: {
    //撤回
    handleClickWithdraw(row) {
      this.$refs.dialogWithdrawRef.open(row);
    },
    //撤回成功
    handleWithdrawSuccess(id, status) {
      let index = this.list.findIndex(item => item.id === id);
      if (index < 0) return;
      let row = this.list[index];
      //修改状态
      if (row) row.shareStatus = status;
      //重置图片
      let listContentRef = this.$refs.listContentRef;
      if (!listContentRef || !listContentRef.length) return;
      let listItem = listContentRef[index];
      if (!listItem) return;
      listItem.thumbUrl = '';
    },
    //修改
    handleClickUpdate(row) {
      this.$refs.dialogSaveRef.open(row);
    },
    //修改成功
    async handleUpdateSuccess(data) {
      let index = this.list.findIndex(item => item.id === data.id);
      if (index < 0) return;
      let row = this.list[index];
      //修改状态
      row.shareStatus = data.shareStatus;
      row.name = data.name || '';
      row.version = data.version || '';
      row.keywords = data.keywords || '';
      row.brand = data.brand || '';
      row.deviceName = data.deviceName || '';
      row.author = data.author || '';
      row.description = data.description || '';
      row.open = data.open;
      await this.$nextTick();
      //初始化样式
      let listContentRef = this.$refs.listContentRef;
      if (!listContentRef || !listContentRef.length) return;
      let listItem = listContentRef[index];
      if (!listItem) return;
      let dom = listItem.$el;
      if (!dom) return;
      listItem.initStyle(dom);
    },
    //删除
    handleClickDelete(row) {
      this.$refs.dialogDeleteRef.open(row);
    },
    //删除成功
    async handleDeleteSuccess(id) {
      let index = this.list.findIndex(item => item.id === id);
      if (index >= 0) this.list.splice(index, 1);
      //删除缓存数据
      let resourceList = this.$store.state.resourceList;
      let indexInRes = resourceList.findIndex(item => item.id === id);
      if (indexInRes >= 0) resourceList.splice(indexInRes, 1);
      //更新条数
      let resourceCount = this.$store.state.resourceCount;
      resourceCount = resourceCount - 1 < 0 ? 0 : resourceCount - 1;
      this.$store.state.resourceCount = resourceCount;
    },
    //点击下载
    async handleClickDounload(item) {
      let type = item.resourcesType;
      let url = await getResourceDownloadUrl(type, item.id);
      if (!url) return;
      let filename = item.name + '_' + item.version + '.te';
      downloadFile(url, filename);
      item.downloadCount += 1;
    }
  }
};