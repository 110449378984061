import {
  ref,
  nextTick
} from 'vue';
import {
  useStore
} from 'vuex';

export default function useNav(sort, search, initSort, initShareList, initMineList) {
  //store
  const store = useStore();

  //导航列表
  const navList = ref([{
      label: 'UITemplate',
      value: 'template',
      type: 'template',
    },
    {
      label: 'DriverCode',
      value: 'driver',
      type: 'driver',
    },
    {
      label: 'DrivePackage',
      value: 'package',
      type: 'package',
    },
    {
      label: 'MyResources',
      value: 'mine',
    },
  ]);
  //当前选中-导航
  const navCheck = ref(navList.value[0]);


  /**点击导航*/
  async function handleNavClick(item) {
    //记录离开前的滚动高度
    let scroll = store.state.shareScroll[navCheck.value.value];
    if (scroll) scroll.top = document.querySelector('.list-scroll-wrap').scrollTop;
    //初始化导航
    if (navCheck.value === item) return;
    navCheck.value = {};
    let sortType = sort.value.type;
    if (item.value === 'template') {
      sortType.options = sortType.templates;
    } else if (item.value === 'driver' || item.value === 'package') {
      sortType.options = sortType.drivers;
    }
    await nextTick();
    navCheck.value = item;
    //切换数据
    if (item.type) { //驱动 驱动包 UI模板
      //获取参数
      let params = store.state.shareParams[item.type];
      //设置搜索输入框
      search.value = params['QV_name'] || '';
      //vuex有缓存 则无需重新请求
      let oldList = store.state.shareList[item.type];
      if (oldList) {
        initSort(params); //保留上一次排序条件
        initShareList(oldList);
      } else {
        initSort(); //还原
        initShareList(params);
      }
    } else { //我的资源
      let params = store.state.mineParams;
      //设置搜索输入框
      search.value = params['QV_name'] || '';
      initSort(params);
      initMineList(params, true);
    }
  };

  return {
    //data
    navList,
    navCheck,
    //function
    handleNavClick
  }
}