import {
  ref,
} from 'vue';
import {
  useStore
} from 'vuex';

export default function useSearch(initShareList, initMineList) {
  //store
  const store = useStore();
  //搜索
  const search = ref('');

  //function
  /**搜索*/
  async function handleSearch(check) {
    //清空滚动高度
    let scroll = store.state.shareScroll[check];
    if (scroll) scroll.top = 0;
    let value = search.value.trim();
    //我的资源
    if (check === 'mine') {
      let params = JSON.parse(JSON.stringify(store.state.mineParams));
      setParams(params, value);
      store.commit('setMineParams', params);
      initMineList(params);
    } else {
      //驱动 驱动包 UI模板
      let params = JSON.parse(JSON.stringify(store.state.shareParams[check]));
      setParams(params, value);
      store.commit('setShareParams', {
        key: check,
        val: params
      });
      initShareList(params);
    }
  };

  /**设置参数*/
  function setParams(params, value) {
    if (value) {
      params['QV_name'] = value;
      params['QV_keywords'] = value;
      params['QV_author'] = value;
      params['QV_description'] = value;
    } else {
      delete params['QV_name'];
      delete params['QV_keywords'];
      delete params['QV_author'];
      delete params['QV_description'];
    }
  };

  return {
    //data
    search,
    //function
    handleSearch
  }
};