import { alertMessage } from '@/assets/js/tool.js';
import { shareCancel } from '@/service/index.js';
import { ElLoading } from 'element-plus';
export default {
  emits: ['success'],
  computed: {
    //我的资源列表
    mineList() {
      return this.$store.state.mineList;
    }
  },
  data() {
    return {
      dialogVisible: false,
      id: '',
      type: -1
    };
  },
  methods: {
    //打开
    async open(row) {
      this.id = row ? row.id || '' : '';
      this.type = row.resourcesType;
      this.dialogVisible = true;
    },
    //确认
    async confirm() {
      let id = this.id;
      if (!id) {
        alertMessage('error', this.$t('message.OperationFailed'));
        return;
      }
      let Loading = ElLoading.service({
        lock: true,
        background: 'rgba(0, 0, 0, 0)'
      });
      let res = await shareCancel(this.type, id);
      //let res = { status: 0 };
      Loading.close();
      if (res.status === 0) {
        alertMessage('success', this.$t('message.OperationSucceeded'));
        //获取Index
        let dataInAll = this.mineList.all.find(item => item.id === id);
        let dataInDriver = this.mineList.driver.find(item => item.id === id);
        let dataInPackage = this.mineList.package.find(item => item.id === id);
        let dataInUi = this.mineList.template.find(item => item.id === id);
        //修改状态
        let ToSubmit = 'ToSubmit';
        this.updateStatus(dataInAll, ToSubmit);
        this.updateStatus(dataInDriver, ToSubmit);
        this.updateStatus(dataInPackage, ToSubmit);
        this.updateStatus(dataInUi, ToSubmit);
        this.$emit('success', id, ToSubmit);
        this.close();
      }
    },
    //取消
    close() {
      this.dialogVisible = false;
    },
    //修改状态为审核中
    updateStatus(data, status) {
      if (data) {
        data.shareStatus = status;
      }
    }
  }
};