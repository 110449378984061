import {
  useStore
} from 'vuex';
import {
  getShareList,
  getMineData
} from '@/service/index.js';


export default function useList() {
  //data
  const shareType = {
    0: 'driver',
    1: 'package',
    2: 'template'
  }
  //store
  const store = useStore();

  //function
  /**初始化驱动 驱动包 UI模板列表*/
  async function initShareList(data) {
    //data为数组时 取的是vuex缓存的数据 无需重新请求
    if (data instanceof Array) {
      store.state.resourceCount = data.length;
      store.commit('setResourceList', JSON.parse(JSON.stringify(data)));
    } else {
      //data为参数时 重新请求
      store.state.loading = true;
      store.commit('setResourceList', []);
      let list = await getShareList(data);
      store.state.resourceCount = list.length;
      store.commit('setShareList', {
        key: shareType[data.type],
        val: list
      });
      store.commit('setResourceList', list);
      store.state.loading = false;
    };
  };

  /**初始化我的资源列表*/
  async function initMineList(params = {}, refresh = false) {
    store.state.loading = true;
    store.commit('setResourceList', []);
    //重新请求
    if (refresh) {
      let mine = await getMineData();
      store.dispatch('setMineList', mine);
    };
    let type = params ? params['Q_Type'] || 'all' : 'all';
    let list = JSON.parse(JSON.stringify(store.state.mineList[type]));
    let newList = [];
    if (!params || !Object.keys(params).length) {
      newList = list;
    } else {
      newList = getSearchSortList(params, list);
    };
    store.state.resourceCount = newList.length;
    store.commit('setResourceList', newList);
    store.state.loading = false;
  };

  /**根据参数筛选列表*/
  function getSearchSortList(params, list) {
    let idArr = [],
      fuzzyList = null;
    let specify = {};
    let sort = {};
    //模糊搜索
    for (let k in params) {
      //模糊搜索 存在多个 且val一致
      if (k.indexOf('QV_') > -1) {
        if (!fuzzyList) fuzzyList = [];
        let key = k.replace('QV_', '');
        let val = params[k].toUpperCase();
        list.forEach(item => {
          let value = item[key].toUpperCase();
          if (value.indexOf(val) > -1) {
            if (idArr.indexOf(item.id) === -1) {
              idArr.push(item.id);
              fuzzyList.push(item);
            };
          };
        });
      }
      //指定搜索仅有一个
      if (k.indexOf('Q_EQ_') > -1) {
        let key = k.replace('Q_EQ_', '');
        let val = params[k];
        specify.key = key;
        specify.val = val;
      };
      //搜索只会存在一个
      if (k.indexOf('S_') > -1) {
        let key = k.replace('S_', '');
        let val = params[k];
        sort.key = key;
        sort.val = val;
      }
    };
    fuzzyList = fuzzyList ? fuzzyList : list;
    //指定搜索
    let specifyList = [];
    if (specify.key) {
      fuzzyList.forEach(item => {
        if (item[specify.key] === specify.val) {
          specifyList.push(item);
        }
      })
    } else {
      specifyList = fuzzyList;
    }
    //name搜索为特殊逻辑
    if (!sort.key) return specifyList;
    if (sort.key === 'name') {
      sortNameAndVersion(specifyList)
    };
    return specifyList;
  }

  /**根据名称+版本号排序*/
  function sortNameAndVersion(list) {
    let pattern = new RegExp("[A-Za-z]+");
    list.sort((a, b) => {
      let nameA = a.name.charAt(0);
      nameA = pattern.test(nameA) ? nameA.toUpperCase() : nameA;
      let nameB = b.name.charAt(0);
      nameB = pattern.test(nameB) ? nameB.toUpperCase() : nameB;
      let A = nameA.charCodeAt();
      let B = nameB.charCodeAt();
      if (A > B) {
        return 1;
      } else if (A == B) {
        let res = compareVersion(a.version, b.version);
        return res > 0 ? -1 : 1;
      } else {
        return -1;
      }
    });
  };

  /**比较版本大小*/
  function compareVersion(v1, v2) {
    let length1 = v1.split('.').length;
    let length2 = v2.split('.').length;
    let maxLength = length1 >= length2 ? length1 : length2;
    v1 = versionToNumber(v1, maxLength);
    v2 = versionToNumber(v2, maxLength);
    return v2 - v1;
  };

  //version字符串转换为number
  function versionToNumber(version, maxLength) {
    let arr = version.split('.');
    if (!arr[0]) arr[0] = 1;
    for (let i = 0; i < maxLength; i++) {
      if (!arr[i]) arr[i] = 0;
    };
    if (!arr[0]) arr[0] = 1;
    let num = Number(arr.join(''));
    return num;
  };

  return {
    //function
    initShareList,
    initMineList
  }
};