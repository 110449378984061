import { nextTick } from 'vue';
import DriverList from './DriverList.vue';
import MineList from './MineList.vue';
export default {
  components: {
    DriverList,
    MineList
  },
  props: {
    type: String
  },
  computed: {
    resourceList() {
      return this.$store.state.resourceList || [];
    },
    loading() {
      return this.$store.state.loading;
    }
  },
  data() {
    return {
      page: 1,
      limit: 15,
      total: 1,
      //总页码
      disabled: true,
      //禁用无线滚动
      scrollList: [],
      noMore: false,
      //没有更多数据
      noData: false //无数据
    };
  },

  methods: {
    //加载更多
    handleLoadMore() {
      let newPage = this.page + 1;
      if (newPage > this.total) {
        this.noMore = true;
        this.disabled = true;
        return;
      }
      this.page = newPage;
      //记录当前滚动页码
      this.$store.state.shareScroll[this.type].page = newPage;
      this.scrollList = this.getScrollList(this.resourceList, newPage);
    },
    //计算滚动列表的数据
    getScrollList(list, page) {
      //深拷贝-不影响原数据
      let newList = JSON.parse(JSON.stringify(list));
      let count = page * this.limit;
      let result = newList.slice(0, count);
      return result;
    }
  },
  watch: {
    async resourceList(newList) {
      this.scrollList = [];
      this.noMore = false;
      this.noData = false;
      await this.$nextTick();
      if (!this.type) return;
      if (!newList.length) {
        this.page = 1;
        this.total = 1;
        this.disabled = true;
        this.noData = true;
      } else {
        //我的资源没有shareScroll[type]对象
        let shareScrollItem = this.$store.state.shareScroll[this.type] || {};
        this.page = shareScrollItem.page || 1;
        this.total = Math.ceil(newList.length / this.limit);
        this.disabled = true;
        this.scrollList = this.getScrollList(newList, this.page);
        await this.$nextTick();
        let wrapHeight = document.querySelector('.list-scroll-wrap').offsetHeight;
        let contentHeight = document.querySelector('.scroll-list').offsetHeight;
        //内容高度大于盒子高度
        let isMaxHeight = contentHeight > wrapHeight;
        if (isMaxHeight) {
          this.disabled = false;
        }
        //已经是最后一页了
        if (this.total === this.page) {
          this.disabled = true;
          if (isMaxHeight) this.noMore = true;
        }
        await nextTick();
        //还原滚动条
        let scroll = this.$store.state.shareScroll[this.type];
        let top = scroll ? scroll.top || 0 : 0;
        document.querySelector('.list-scroll-wrap').scrollTop = top;
      }
    }
  }
};