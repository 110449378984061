import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode } from "vue";
const _hoisted_1 = {
  class: "dialog-content"
};
const _hoisted_2 = {
  class: "dialog-footer"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_input = _resolveComponent("el-input");
  const _component_el_form_item = _resolveComponent("el-form-item");
  const _component_el_radio = _resolveComponent("el-radio");
  const _component_el_radio_group = _resolveComponent("el-radio-group");
  const _component_el_form = _resolveComponent("el-form");
  const _component_el_button = _resolveComponent("el-button");
  const _component_el_dialog = _resolveComponent("el-dialog");
  return _openBlock(), _createBlock(_component_el_dialog, {
    modelValue: $data.dialogVisible,
    "onUpdate:modelValue": _cache[8] || (_cache[8] = $event => $data.dialogVisible = $event),
    title: $data.title || _ctx.$t('message.Tips'),
    width: "550px",
    top: "25vh",
    "custom-class": "my-dialog  save-dialog",
    "close-on-click-modal": false
  }, {
    footer: _withCtx(() => [_createElementVNode("div", _hoisted_2, [_createVNode(_component_el_button, {
      onClick: $options.confirm,
      type: "primary"
    }, {
      default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.$t('message.Submit')), 1)]),
      _: 1
    }, 8, ["onClick"]), _createVNode(_component_el_button, {
      onClick: $options.close
    }, {
      default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.$t('message.Cancel')), 1)]),
      _: 1
    }, 8, ["onClick"])])]),
    default: _withCtx(() => [_createElementVNode("div", _hoisted_1, [$data.showForm ? (_openBlock(), _createBlock(_component_el_form, {
      key: 0,
      model: $data.ruleForm,
      rules: $data.rules,
      ref: "ruleFormRef",
      "label-width": $options.lang === 'en' ? '110px' : '100px',
      class: "save-ruleForm"
    }, {
      default: _withCtx(() => [_createVNode(_component_el_form_item, {
        label: this.$t('message.Name'),
        prop: "name"
      }, {
        default: _withCtx(() => [_createVNode(_component_el_input, {
          modelValue: $data.ruleForm.name,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => $data.ruleForm.name = $event),
          maxlength: 75,
          spellcheck: false,
          disabled: ""
        }, null, 8, ["modelValue"])]),
        _: 1
      }, 8, ["label"]), _createVNode(_component_el_form_item, {
        label: this.$t('message.Version'),
        prop: "version"
      }, {
        default: _withCtx(() => [_createVNode(_component_el_input, {
          modelValue: $data.ruleForm.version,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => $data.ruleForm.version = $event),
          maxlength: 75,
          spellcheck: false,
          disabled: ""
        }, null, 8, ["modelValue"])]),
        _: 1
      }, 8, ["label"]), _createVNode(_component_el_form_item, {
        label: this.$t('message.Keywords'),
        prop: "keywords"
      }, {
        default: _withCtx(() => [_createVNode(_component_el_input, {
          modelValue: $data.ruleForm.keywords,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => $data.ruleForm.keywords = $event),
          spellcheck: false
        }, null, 8, ["modelValue"])]),
        _: 1
      }, 8, ["label"]), $data.type !== 2 ? (_openBlock(), _createBlock(_component_el_form_item, {
        label: this.$t('message.Brand'),
        prop: "brand",
        key: "brand"
      }, {
        default: _withCtx(() => [_createVNode(_component_el_input, {
          modelValue: $data.ruleForm.brand,
          "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => $data.ruleForm.brand = $event),
          maxlength: 75,
          spellcheck: false
        }, null, 8, ["modelValue"])]),
        _: 1
      }, 8, ["label"])) : _createCommentVNode("", true), $data.type !== 2 ? (_openBlock(), _createBlock(_component_el_form_item, {
        label: this.$t('message.DeviceName'),
        prop: "deviceName",
        key: "deviceName"
      }, {
        default: _withCtx(() => [_createVNode(_component_el_input, {
          modelValue: $data.ruleForm.deviceName,
          "onUpdate:modelValue": _cache[4] || (_cache[4] = $event => $data.ruleForm.deviceName = $event),
          maxlength: 75,
          spellcheck: false
        }, null, 8, ["modelValue"])]),
        _: 1
      }, 8, ["label"])) : _createCommentVNode("", true), _createVNode(_component_el_form_item, {
        label: this.$t('message.Author'),
        prop: "author"
      }, {
        default: _withCtx(() => [_createVNode(_component_el_input, {
          modelValue: $data.ruleForm.author,
          "onUpdate:modelValue": _cache[5] || (_cache[5] = $event => $data.ruleForm.author = $event),
          maxlength: 50,
          spellcheck: false
        }, null, 8, ["modelValue"])]),
        _: 1
      }, 8, ["label"]), _createVNode(_component_el_form_item, {
        label: this.$t('message.Description'),
        prop: "description"
      }, {
        default: _withCtx(() => [_createVNode(_component_el_input, {
          modelValue: $data.ruleForm.description,
          "onUpdate:modelValue": _cache[6] || (_cache[6] = $event => $data.ruleForm.description = $event),
          type: "textarea",
          maxlength: 1000,
          spellcheck: false
        }, null, 8, ["modelValue"])]),
        _: 1
      }, 8, ["label"]), _createVNode(_component_el_form_item, {
        label: this.$t('message.ReleaseScope'),
        prop: "open",
        class: "form-radio"
      }, {
        default: _withCtx(() => [_createVNode(_component_el_radio_group, {
          modelValue: $data.ruleForm.open,
          "onUpdate:modelValue": _cache[7] || (_cache[7] = $event => $data.ruleForm.open = $event)
        }, {
          default: _withCtx(() => [_createVNode(_component_el_radio, {
            label: false
          }, {
            default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.$t('message.OnlyVisible')), 1)]),
            _: 1
          }), _createVNode(_component_el_radio, {
            label: true
          }, {
            default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.$t('message.Open')), 1)]),
            _: 1
          })]),
          _: 1
        }, 8, ["modelValue"])]),
        _: 1
      }, 8, ["label"])]),
      _: 1
    }, 8, ["model", "rules", "label-width"])) : _createCommentVNode("", true)])]),
    _: 1
  }, 8, ["modelValue", "title"]);
}