import { downloadFile } from '@/assets/js/tool.js';
import { getResourceDownloadUrl } from '@/service/index.js';
import ListContent from './ListContent.vue';
export default {
  components: {
    ListContent
  },
  props: {
    list: {
      type: Array,
      default() {
        return [];
      }
    }
  },
  created() {
    //计算分页数据
  },
  data() {
    return {
      page: 1,
      limit: 10,
      showList: []
    };
  },
  methods: {
    //点击下载
    async handleClickDownload(item) {
      let resourceNav = this.$store.state.resourceNav;
      let type = resourceNav === 'driver' ? 0 : resourceNav === 'package' ? 1 : 2;
      let url = await getResourceDownloadUrl(type, item.id);
      if (!url) return;
      let filename = item.name + '_' + item.version + '.te';
      downloadFile(url, filename);
      item.downloadCount += 1;
    }
  }
};