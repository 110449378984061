import { alertMessage } from '@/assets/js/tool.js';
import { shareDelete } from '@/service/index.js';
export default {
  emits: ['success'],
  computed: {
    //我的资源列表
    mineList() {
      return this.$store.state.mineList;
    }
  },
  data() {
    return {
      dialogVisible: false,
      id: '',
      type: -1
    };
  },
  methods: {
    //打开
    async open(row) {
      this.id = row ? row.id || '' : '';
      this.type = row.resourcesType;
      this.dialogVisible = true;
    },
    //确认
    async confirm() {
      let id = this.id;
      if (!id) {
        alertMessage('error', this.$t('message.OperationFailed'));
        return;
      }
      let Loading = ElLoading.service({
        lock: true,
        background: 'rgba(0, 0, 0, 0)'
      });
      let res = await shareDelete(this.type, id);
      //let res = { status: 0 };
      Loading.close();
      if (res.status === 0) {
        alertMessage('success', this.$t('message.OperationSucceeded'));
        //获取Index
        let indexInAll = this.mineList.all.findIndex(item => item.id === id);
        let indexInDriver = this.mineList.driver.findIndex(item => item.id === id);
        let indexInPackage = this.mineList.package.findIndex(item => item.id === id);
        let indexInUi = this.mineList.template.findIndex(item => item.id === id);
        //删除
        this.deleteData(indexInAll, this.mineList.all);
        this.deleteData(indexInDriver, this.mineList.driver);
        this.deleteData(indexInPackage, this.mineList.package);
        this.deleteData(indexInUi, this.mineList.template);
        this.$emit('success', id);
        this.close();
      }
    },
    //取消
    close() {
      this.dialogVisible = false;
    },
    //删除数据
    deleteData(index, list) {
      if (index < 0) return;
      list.splice(index, 1);
    }
  }
};