import { Close } from '@element-plus/icons-vue';
export default {
  components: {
    Close
  },
  computed: {
    image() {
      return this.$store.state.image;
    }
  },
  data() {
    return {
      show: false,
      zoom: 100,
      proportion: [25, 33, 50, 67, 75, 80, 90, 100, 110, 125, 150, 175, 200, 250, 300, 400, 500],
      contentWidth: 600,
      contentHeight: 400,
      img: {
        enlarge: require('../../assets/img/enlarge.png'),
        narrow: require('../../assets/img/narrow.png'),
        '1to1': require('../../assets/img/1to1.png')
      },
      posX: 0,
      //记录当前图片偏移量
      posY: 0,
      old: {
        x: 0,
        //上一次 移动的距离
        y: 0,
        width: 'auto',
        //初始宽度
        height: 'auto',
        zoom: 100 //记录上一次zoom
      },

      notClose: false //禁止拖动时松开鼠标关闭
    };
  },

  created() {
    this.SubPro = [...this.proportion].reverse();
  },
  mounted() {
    document.onkeydown = event => {
      if (event.keyCode == 27 && this.show) {
        this.show = false;
      }
    };
  },
  methods: {
    initBodyClick() {
      document.body.onclick = () => {
        if (this.notClose) {
          this.notClose = false;
          return;
        }
        let image = this.$store.state.image || '';
        if (!image) return;
        this.close();
      };
    },
    //滚动事件函数
    initOnwheel(e) {
      let delta = e.wheelDelta && (e.wheelDelta > 0 ? 1 : -1);
      //缩小
      if (delta > 0) {
        this.add();
      } else if (delta < 0) {
        this.subtraction();
      }
    },
    //初始化盒子大小
    initBoxSize() {
      let imgRef = this.$refs.imgRef;
      imgRef.addEventListener('load', () => {
        //如果高度过大
        let zoom1 = 100;
        let zoom2 = 100;
        if (imgRef.height > this.contentHeight) {
          let zoom = this.getZoom(imgRef.height, this.contentHeight);
          zoom1 = zoom;
        }
        //如果宽度过大
        if (imgRef.width > this.contentWidth) {
          let zoom = this.getZoom(imgRef.width, this.contentWidth);
          zoom2 = zoom;
        }
        this.zoom = zoom1 < zoom2 ? zoom1 : zoom2;
      });
    },
    //加
    add() {
      if (this.zoom >= 500) return;
      let zoom = this.proportion.find(item => item > this.zoom);
      if (!zoom) zoom = 500;
      this.zoom = zoom;
    },
    //减
    subtraction() {
      if (this.zoom <= 25) return;
      let zoom = this.SubPro.find(item => item < this.zoom);
      if (!zoom) zoom = 25;
      this.zoom = zoom;
    },
    //点击弹窗关闭
    close() {
      this.$store.state.image = '';
    },
    //鼠标移入
    mouseenter() {
      document.addEventListener('wheel', this.initOnwheel, false);
    },
    //鼠标移出
    mouseleave() {
      document.removeEventListener('wheel', this.initOnwheel, false);
    },
    //计算Zoom
    getZoom(big, small) {
      let percent = big / (big + small) * 100;
      //下取整
      percent = Math.floor(percent);
      return percent <= 25 ? 25 : percent;
    },
    //一比一复位
    oneToOne() {
      this.zoom = 100;
      this.initPos('xy');
    },
    //初始化位置
    initPos(type, pos = 0) {
      if (type === 'x') {
        this.posX = pos;
        this.old.x = pos;
      } else if (type === 'y') {
        this.posY = pos;
        this.old.y = pos;
      } else {
        this.posX = pos;
        this.old.x = pos;
        this.posY = pos;
        this.old.y = pos;
      }
    },
    //初始化图片拖动
    initMouseDown() {
      let that = this;
      let dom = this.$refs.imgRef;
      dom.onmousedown = function (e) {
        let x = e.clientX;
        let y = e.clientY;
        let oldX = 0;
        let oldY = 0;
        document.onmousemove = function (e) {
          that.notClose = true;
          let imgW = parseFloat(dom.offsetWidth) * (that.zoom / 100);
          let imgH = parseFloat(dom.offsetHeight) * (that.zoom / 100);
          //宽度大于盒子 允许左右滚动
          if (imgW > that.contentWidth) {
            let newX = e.clientX - x + that.old.x;
            let rangeW = Math.abs((imgW - that.contentWidth) / 2); //绝对值
            newX = newX < rangeW * -1 ? rangeW * -1 : newX;
            newX = newX > rangeW ? rangeW : newX;
            that.posX = newX;
            oldX = newX;
          }
          //宽度小于盒子 允许上下滚动
          if (imgH > that.contentHeight) {
            let newY = e.clientY - y + that.old.y;
            let rangeY = Math.abs((imgH - that.contentHeight) / 2); //绝对值
            newY = newY < rangeY * -1 ? rangeY * -1 : newY;
            newY = newY > rangeY ? rangeY : newY;
            that.posY = newY;
            oldY = newY;
          }
        };
        //最后让他的鼠标按下和移动都为null，防止继续执行
        document.onmouseup = function () {
          that.old.x = oldX;
          that.old.y = oldY;
          document.onmousemove = document.onmousedown = null;
        };
      };
    }
  },
  watch: {
    async image(newImg) {
      if (newImg) {
        this.show = true;
        this.initBodyClick();
        this.zoom = 100;
        await this.$nextTick();
        this.initBoxSize();
        this.initPos('xy');
        this.initMouseDown();
      } else {
        this.show = false;
        document.body.onclick = null;
      }
    },
    async zoom(newZoom) {
      let num = newZoom / 100;
      let w = this.old.width * num;
      let h = this.old.height * num;
      //缩小 重置为中心位置
      if (newZoom < this.old.zoom) {
        this.initPos('xy');
      }
      this.old.zoom = newZoom;
    }
  }
};