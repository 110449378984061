import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx } from "vue";
const _hoisted_1 = {
  class: "driver-list"
};
const _hoisted_2 = {
  class: "item-content"
};
const _hoisted_3 = {
  class: "item-status"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_list_content = _resolveComponent("list-content");
  const _component_el_link = _resolveComponent("el-link");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.list, item => {
    return _openBlock(), _createElementBlock("div", {
      class: "driver-list-item",
      key: item.id
    }, [_createElementVNode("div", _hoisted_2, [_createVNode(_component_list_content, {
      content: item
    }, null, 8, ["content"])]), _createElementVNode("div", _hoisted_3, [_createVNode(_component_el_link, {
      type: "primary",
      onClick: $event => $options.handleClickDownload(item)
    }, {
      default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.$t('message.download')), 1)]),
      _: 2
    }, 1032, ["onClick"])])]);
  }), 128))]);
}