import { alertMessage } from '@/assets/js/tool.js';
import { getUiThumbUrl } from '@/service/index.js';
import { Loading } from '@element-plus/icons-vue';
export default {
  components: {
    Loading
  },
  props: {
    content: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  computed: {
    //所有版本信息
    allVersion() {
      return this.$store.state.allVersion;
    },
    //资源下载左侧导航栏
    resourceNav() {
      return this.$store.state.resourceNav;
    }
  },
  data() {
    return {
      boxHeight: 40,
      //描述盒子高度
      contentHeight: 0,
      //描述内容高度
      titleWidth: 40,
      //标题宽度
      tagItemWidth: 'auto' //每个关键字宽度
    };
  },

  async mounted() {
    await this.$nextTick();
    let listContentRef = this.$refs.listContentRef;
    if (!listContentRef) return;
    this.initStyle(listContentRef);
  },
  methods: {
    //初始化样式
    initStyle(listContentRef) {
      //设置描述文本溢出隐藏
      this._setDescriptionEllipsie(listContentRef);
      //设置标题盒子宽度
      this._setTitleWidth(listContentRef);
      //设置每个关键字宽度
      this._setTagWidth(listContentRef);
    },
    //设置描述文本溢出隐藏
    _setDescriptionEllipsie(listContentRef) {
      let text = listContentRef.querySelector('.text-description');
      let height = text.offsetHeight;
      if (height > this.boxHeight) {
        this.contentHeight = height;
      }
    },
    //设置标题盒子宽度
    _setTitleWidth(listContentRef) {
      let titlebox = listContentRef.querySelector('.title-box');
      let boxWidth = parseInt(titlebox.style.width) ? parseInt(titlebox.style.width) : titlebox.offsetWidth;
      //设置宽度 解决修改后重新调用initStyle方法 错位问题
      if (!titlebox.style.width) titlebox.style.width = boxWidth + 'px';
      let version = titlebox.querySelector('.version');
      let versionWidth = version.offsetWidth;
      let img = titlebox.querySelector('.eye');
      let imgWidth = img.offsetWidth;
      let privates = titlebox.querySelector('.private');
      //加上margin的10px
      let privateWidth = privates ? privates.offsetWidth + 10 : 0;
      let titleWidth = boxWidth - versionWidth - imgWidth - privateWidth;
      this.titleWidth = titleWidth;
    },
    //设置每个关键字宽度
    _setTagWidth(listContentRef) {
      this.tagItemWidth = 'auto';
      let tagbox = listContentRef.querySelector('.tag-box');
      let tagboxWidth = tagbox.offsetWidth;
      let tagItem = tagbox.querySelectorAll('.tag-item');
      if (!tagItem || tagItem.length < 1) return;
      let itemAllWidth = 0;
      for (let i = 0; i < tagItem.length; i++) {
        let itemWidth = tagItem[i].offsetWidth;
        itemAllWidth += itemWidth;
      }
      let marginWidth = (tagItem.length - 1) * 6;
      itemAllWidth += marginWidth;
      if (itemAllWidth > tagboxWidth) {
        let newWidth = Math.floor((tagboxWidth - marginWidth) / tagItem.length);
        this.tagItemWidth = newWidth <= 0 ? 'auto' : newWidth + 'px';
      }
    },
    //文本溢出隐藏
    getTextEllipsis(text, number = 20) {
      text = text.trim();
      if (text.length <= number) return text;
      return text.substring(0, number) + '...';
    },
    //查看更多
    lookMore() {
      this.boxHeight = this.contentHeight;
    },
    //查看文档或者大图
    handleLook() {
      let thumbUrl = this.content.thumbUrl;
      //ui模板
      if (thumbUrl !== undefined) {
        this.lookBigImg();
      } else {
        //驱动 驱动包
        this.lookDocumentation();
      }
    },
    //查看大图
    async lookBigImg() {
      if (!this.content.thumbUrl) {
        alertMessage('error', this.$t('message.NoImg'));
        return;
      }
      this.$store.state.image = '';
      let newUrl = await getUiThumbUrl(this.content.id);
      this.$store.state.image = newUrl;
    },
    //查看文档
    lookDocumentation() {
      let id = this.content.id || '';
      let type = this.getType(this.content);
      this.$store.state.rowType = type;
      this.$store.state.rowId = id;
      let apiDoc = this.content.apiDoc;
      if (!apiDoc) {
        alertMessage('error', this.$t('message.OperationFailed'));
        return;
      }
      apiDoc = JSON.parse(apiDoc);
      this.$store.commit('setApiDoc', apiDoc);
    },
    //获取分类
    getType(content) {
      let type = undefined;
      if (this.resourceNav === 'mine') {
        type = content.resourcesType;
      } else if (this.resourceNav === 'driver') {
        type = 0;
      } else if (this.resourceNav === 'package') {
        type = 1;
      }
      return type;
    }
  }
};