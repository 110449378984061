import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, normalizeStyle as _normalizeStyle } from "vue";
import _imports_0 from '../../../assets/img/why.png';
import _imports_1 from '../../../assets/img/back.png';
import _imports_2 from '../../../assets/img/update.png';
import _imports_3 from '../../../assets/img/delete.png';
import _imports_4 from '../../../assets/img/mine-download.png';
const _hoisted_1 = {
  class: "mine-list"
};
const _hoisted_2 = {
  class: "item-content"
};
const _hoisted_3 = {
  class: "item-status"
};
const _hoisted_4 = ["title"];
const _hoisted_5 = {
  key: 0,
  src: _imports_0
};
const _hoisted_6 = {
  class: "img-box"
};
const _hoisted_7 = ["title", "onClick"];
const _hoisted_8 = ["title", "onClick"];
const _hoisted_9 = ["title", "onClick"];
const _hoisted_10 = ["title", "onClick"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_list_content = _resolveComponent("list-content");
  const _component_withdraw = _resolveComponent("withdraw");
  const _component_delete = _resolveComponent("delete");
  const _component_save = _resolveComponent("save");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.list, item => {
    return _openBlock(), _createElementBlock("div", {
      class: "mine-list-item",
      key: item.id
    }, [_createElementVNode("div", _hoisted_2, [_createVNode(_component_list_content, {
      content: item,
      ref_for: true,
      ref: "listContentRef"
    }, null, 8, ["content"])]), _createElementVNode("div", _hoisted_3, [_createElementVNode("p", {
      class: "status-text",
      title: item.shareStatus === 'Reject' ? item.reviewInfo || '' : '',
      style: _normalizeStyle({
        color: item.shareStatus === 'Reject' ? '#b22d2d' : item.shareStatus === 'Deployed' ? '#999999' : '#1a3880'
      })
    }, [_createTextVNode(_toDisplayString(_ctx.$t(`message.${item.shareStatus}`)) + " ", 1), item.shareStatus === 'Reject' ? (_openBlock(), _createElementBlock("img", _hoisted_5)) : _createCommentVNode("", true)], 12, _hoisted_4), _createElementVNode("div", _hoisted_6, [item.shareStatus === 'Checking' || item.shareStatus === 'Deployed' ? (_openBlock(), _createElementBlock("img", {
      key: 0,
      src: _imports_1,
      title: _ctx.$t('message.Withdraw'),
      onClick: $event => $options.handleClickWithdraw(item)
    }, null, 8, _hoisted_7)) : _createCommentVNode("", true), item.shareStatus === 'Reject' || item.shareStatus === 'ToSubmit' ? (_openBlock(), _createElementBlock("img", {
      key: 1,
      src: _imports_2,
      title: _ctx.$t('message.Update'),
      onClick: $event => $options.handleClickUpdate(item)
    }, null, 8, _hoisted_8)) : _createCommentVNode("", true), _createElementVNode("img", {
      src: _imports_3,
      title: _ctx.$t('message.Delete'),
      onClick: $event => $options.handleClickDelete(item)
    }, null, 8, _hoisted_9), _createElementVNode("img", {
      src: _imports_4,
      title: _ctx.$t('message.download'),
      onClick: $event => $options.handleClickDounload(item),
      class: "download-img"
    }, null, 8, _hoisted_10)])])]);
  }), 128)), _createVNode(_component_withdraw, {
    onSuccess: $options.handleWithdrawSuccess,
    ref: "dialogWithdrawRef"
  }, null, 8, ["onSuccess"]), _createVNode(_component_delete, {
    onSuccess: $options.handleDeleteSuccess,
    ref: "dialogDeleteRef"
  }, null, 8, ["onSuccess"]), _createVNode(_component_save, {
    onSuccess: $options.handleUpdateSuccess,
    ref: "dialogSaveRef"
  }, null, 8, ["onSuccess"])]);
}